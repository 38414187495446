:root {
    --size: clamp(10rem, 1rem + 40vmin, 30rem);
    --gap: calc(var(--size) / 5);
    --duration: 40s; /* Increased duration for slower animation */
    --scroll-start: 0%; /* Ensure the marquee starts from the beginning */
    --scroll-end: calc(-100% - var(--gap));
}

.wrapper {
    display: flex;
    flex-direction: column;
    gap: var(--gap);
    margin: auto;
    max-width: 100vw;
}

@keyframes scroll-x {
    from {
        transform: translateX(var(--scroll-start));
    }
    to {
        transform: translateX(var(--scroll-end));
    }
}

.marquee {
    display: flex;
    overflow: hidden;
    user-select: none;
    gap: var(--gap);
    mask-image: linear-gradient(
            var(--mask-direction, to right),
            hsl(0 0% 0% / 0),
            hsl(0 0% 0% / 1) 20%,
            hsl(0 0% 0% / 1) 80%,
            hsl(0 0% 0% / 0)
    );
}

.marquee__group {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: var(--gap);
    min-width: 100%;
    animation: scroll-x var(--duration) linear infinite;
    animation-delay: 0s; /* Ensure no delay in animation */
}

.marquee--reverse .marquee__group {
    animation-direction: reverse;
    animation-delay: -3s;
}

.hidden-col {
    display: none;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
}

.shadow-text {
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.7);
}

.shadow-text-gold {
    text-shadow: 0 0 15px #ffd700;
}

@keyframes pulse-slow {
    0%, 100% {
        opacity: 1;
        box-shadow: 0 0 20px #ffd700;
    }
    50% {
        opacity: 0.8;
        box-shadow: 0 0 30px #ffd700;
    }
}

.animate-pulse-slow {
    animation: pulse-slow 3s infinite;
}

.animate-glow {
    animation: glow 2s infinite;
}

@keyframes glow {
    0%, 100% {
        text-shadow: 0 0 15px #ffd700;
    }
    50% {
        text-shadow: 0 0 25px #ffd700, 0 0 35px #ffd700;
    }
}